//$font-family-roboto: "proxima_nova_rgregular", sans-serif;
$font-size-base: 1rem;
$font-family-base: "Lato", serif;
$font-family-roboto: $font-family-base;

$mdb-font-family: $font-family-roboto;
$font-family-sans-serif: $font-family-roboto;
$font-family-monospace: $font-family-roboto;

$primary: #4374ff;
$secondary: #f7fafc;
$success: #00d95a;
$info: #4dd6ff;
$warning: #f19f1e;
$danger: #ff2b6e;
$white: #fff;
$black: #000;
$gray-border: #ccd5e0;

$body-bg: #f7f8fb;
$input-bg: #f7fafc;
$input-border-color: $gray-border;
$form-notch-div-border-color: $gray-border;
/*node_modules/mdb-ui-kit/src/scss/free/forms/_form-control.scss*/

$facebook: #3b5a98;
$google: #e74133;
$microsoft: #00adef;

// $border-radius: 		0.5rem;
// $border-radius-sm: 		0.4rem;
// $border-radius-lg: 		0.6rem;

$header-height: 80px;
$footer-height: 0px;
$navbar-height: 80px;

$spacer: 1rem;
$spacers: (
	0: 0,
	q: calc($spacer / 4),
	h: calc($spacer / 2),
	1: $spacer,
	2: calc($spacer * 2),
	3: calc($spacer * 3),
	4: calc($spacer * 4),
	5: calc($spacer * 5),
);

/**************************************************************/

@import "~mdb-ui-kit/src/scss/free/variables";
@import "~mdb-ui-kit/src/scss/bootstrap/variables";
