@font-face {
	font-family: "proxima_nova_rgregular";
	src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot");
	src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix")
			format("embedded-opentype"),
		url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff")
			format("woff"),
		url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf")
			format("truetype"),
		url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular")
			format("svg");
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "proxima_nova_rgbold";
	src: url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot");
	src: url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot?#iefix")
			format("embedded-opentype"),
		url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.woff")
			format("woff"),
		url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.ttf")
			format("truetype"),
		url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.svg#proxima_nova_rgbold")
			format("svg");
	font-weight: normal;
	font-style: normal;
}

.font-size-1_2 {
	font-size: 1.2em;
}
