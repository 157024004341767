@import "./variables";
@import "./fonts";

@import "~mdb-ui-kit/src/scss/free/variables";
@import "~mdb-ui-kit/src/scss/bootstrap/variables";

body {
	--mdb-font-roboto: "proxima_nova_rgregular", sans-serif;
	min-height: 100vh;
}

.fix-scrollbar {
	padding-left: calc(100vw - 100%);
}

.app-body {
	padding-top: calc(#{$header-height} + 3em);
}

input:not([type="checkbox"]) {
	background-color: $input-bg !important;
}

.form-check {
	margin-left: 0.3em;
	& label.form-check-label {
		margin-top: 2px;
	}
}

.line-and-text {
	width: 100%;
	text-align: center;
	border-bottom: 1px solid $gray-border;
	line-height: 0.1em;
	margin: 10px 0 20px;
	span {
		background: #fff;
		padding: 0 10px;
	}
}
.text-facebook {
	color: $facebook;
}
.text-google {
	color: $google;
}
.text-microsoft {
	color: $microsoft;
}
.text-transform-none {
	text-transform: none;
}
.font-family-bold {
	font-family: "proxima_nova_rgbold";
}
.card {
	border-radius: 0.5rem;
}
.center-screen {
	overflow: hidden;
	width: 100%;
	height: 100%;
	min-height: calc(100vh - #{$footer-height} - #{$header-height} - 3em);
	display: flex;
	justify-content: center; /*centers items on the line (the x-axis by default)*/
	// align-items: center; /*centers items on the cross-axis (y by default)*/

	& > * {
		width: 100%;
	}
	&.center-screen-with-padding {
		margin-top: 0;
	}
}
footer {
	min-height: $footer-height;
}

header {
	margin-left: calc((100% - 100vw) / 2);
	box-shadow: none;

	.navbar-logo {
		height: $navbar-brand-height;
	}

	.navbar {
		padding-left: calc((100vw - 100%));
		height: $header-height;
	}
}

.is-invalid,
.is-invalid-label,
.is-invalid-wraper {
	margin-bottom: 0 !important;
}

.btn {
	text-transform: none;
}

.font-sm {
	font-size: 0.75em;
}
.font-1_5 {
	font-size: 1.5em !important;
}

.field-icon {
	position: absolute;
	display: flex;
	right: 0;
	top: 0;
	bottom: 0;
	align-items: center;
	z-index: 4;
	margin-right: 0.5em;
	cursor: default;

	& ~ .field-icon {
		right: 1.5em;
	}
}

.clickable {
	cursor: pointer;
}

.form-error {
	opacity: 0.6;
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
	box-shadow: none !important;
}

.circle {
	border-radius: 1000em !important;
}

.bg-ligh-gray,
.bg-light-grey {
	background-color: $gray-200;
}

.fix-scrollbar {
	padding-left: calc(100vw - 100%) !important;
}

.container {
	max-width: 1140px;
}

.hidden {
	display: none !important;
}
