// CORE FUNCTIONS
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/functions";
@import "../node_modules/mdb-ui-kit/src/scss/free/functions";

// CORE VARIABLES
@import "../node_modules/mdb-ui-kit/src/scss/custom/variables";
@import "../node_modules/mdb-ui-kit/src/scss/free/variables";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/variables";

@import "./styles/variables";

// BOOTSTRAP CORE
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/mixins";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/utilities";

// BOOTSTRAP CORE COMPONENTS
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/root";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/reboot";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/type";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/images";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/containers";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/grid";

// BOOTSTRAP COMPONENTS
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/tables";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/forms";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/buttons";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/transitions";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/dropdown";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/button-group";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/nav";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/navbar";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/card";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/breadcrumb";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/pagination";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/badge";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/alert";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/accordion";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/progress";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/placeholders";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/list-group";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/close";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/toasts";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/modal";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/popover";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/carousel";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/spinners";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/offcanvas";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/tooltip";

// Helpers
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/helpers";

// Utilities
@import "../node_modules/mdb-ui-kit/src/scss/free/utilities";
@import "../node_modules/mdb-ui-kit/src/scss/bootstrap-rtl-fix/utilities/api";

// MDB CORE
@import "../node_modules/mdb-ui-kit/src/scss/free/mixins";
@import "../node_modules/mdb-ui-kit/src/scss/free/utilities";

// MDB CORE COMPONENTS
@import "../node_modules/mdb-ui-kit/src/scss/free/root";
@import "../node_modules/mdb-ui-kit/src/scss/free/reboot";
@import "../node_modules/mdb-ui-kit/src/scss/free/type";
@import "../node_modules/mdb-ui-kit/src/scss/free/colors";
@import "../node_modules/mdb-ui-kit/src/scss/free/shadows";
@import "../node_modules/mdb-ui-kit/src/scss/free/flag";
@import "../node_modules/mdb-ui-kit/src/scss/free/images";

// MDB FORMS
@import "../node_modules/mdb-ui-kit/src/scss/free/forms/form-control";
@import "../node_modules/mdb-ui-kit/src/scss/free/forms/form-select";
@import "../node_modules/mdb-ui-kit/src/scss/free/forms/form-check";
@import "../node_modules/mdb-ui-kit/src/scss/free/forms/form-file";
@import "../node_modules/mdb-ui-kit/src/scss/free/forms/input-group";
@import "../node_modules/mdb-ui-kit/src/scss/free/forms/validation";
@import "../node_modules/mdb-ui-kit/src/scss/free/forms/form-range";

// MDB COMPONENTS
@import "../node_modules/mdb-ui-kit/src/scss/free/tables";
@import "../node_modules/mdb-ui-kit/src/scss/free/buttons";
@import "../node_modules/mdb-ui-kit/src/scss/free/deprecated";
@import "../node_modules/mdb-ui-kit/src/scss/free/dropdown";
@import "../node_modules/mdb-ui-kit/src/scss/free/button-group";
@import "../node_modules/mdb-ui-kit/src/scss/free/nav";
@import "../node_modules/mdb-ui-kit/src/scss/free/navbar";
@import "../node_modules/mdb-ui-kit/src/scss/free/card";
@import "../node_modules/mdb-ui-kit/src/scss/free/breadcrumb";
@import "../node_modules/mdb-ui-kit/src/scss/free/pagination";
@import "../node_modules/mdb-ui-kit/src/scss/free/badge";
@import "../node_modules/mdb-ui-kit/src/scss/free/alert";
@import "../node_modules/mdb-ui-kit/src/scss/free/progress";
@import "../node_modules/mdb-ui-kit/src/scss/free/list-group";
@import "../node_modules/mdb-ui-kit/src/scss/free/close";
@import "../node_modules/mdb-ui-kit/src/scss/free/modal";
@import "../node_modules/mdb-ui-kit/src/scss/free/toasts";
@import "../node_modules/mdb-ui-kit/src/scss/free/tooltip";
@import "../node_modules/mdb-ui-kit/src/scss/free/popover";
@import "../node_modules/mdb-ui-kit/src/scss/free/scrollspy";
@import "../node_modules/mdb-ui-kit/src/scss/free/ripple";
@import "../node_modules/mdb-ui-kit/src/scss/free/range";
@import "../node_modules/mdb-ui-kit/src/scss/free/accordion";
@import "../node_modules/mdb-ui-kit/src/scss/free/carousel";

@import "./styles/styles";
@import "./styles/components";
@import "./styles/responsive/index";

@import "~animate.css/animate.min.css";
