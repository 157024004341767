.component-profile-personal,
.component-profile-account {
	tbody tr td {
		&:first-child {
			width: 60px;
			font-weight: bold;
		}
	}
}
.component-profile-account {
	.edit-view {
		tbody tr td {
			&:first-child {
				min-width: 160px;
			}
		}
	}
}

.bu-wizard {
	.register-nav {
		display: flex;
		margin-bottom: 2rem;

		.nav-unit {
			display: flex;
			align-items: baseline;
			margin-right: 3rem;
			cursor: pointer;
		}

		.active {
			color: $primary;
		}
	}
	.rsw_2Y {
		margin-top: 2em;
	}
}

.autocomplete-dropdown-container {
	width: 100%;
	margin: 0px;
	position: absolute;
	inset: auto auto 0px 0px;
	z-index: 1050;
	top: 0;

	.autocomplete-dropdown {
		background-color: #fff;
		box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
			0 2px 10px 0 rgba(0, 0, 0, 0.12);
		margin: 0;
		outline: 0;
		position: relative;
		transform: scaleY(0.8);
		opacity: 0;
		transition: all 0.2s;

		&.open {
			transform: scaleY(1);
			opacity: 1;
		}
		.autocomplete-items-list {
			list-style: none;
			margin: 0;
			padding: 0;
			overflow-y: auto;
		}
		.autocomplete-item {
			width: 100%;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: 6.5px 16px;
			font-weight: 400;
			background-color: transparent;
			user-select: none;
		}
		.autocomplete-item.active,
		.autocomplete-item:hover:not(.disabled) {
			background-color: #ddd;
		}
		.autocomplete-item.disabled {
			cursor: default;
			color: #9e9e9e;
		}
		.autocomplete-loader {
			position: absolute;
			right: 5px;
			top: 8px;
			width: 1.4rem;
			height: 1.4rem;
			border-width: 0.15em;
		}
	}
}

.ajax-button {
	position: relative;
	display: inline-block;
	padding: 0;

	button {
		z-index: 10;
		text-transform: none;

		&,
		&:hover,
		&:focus,
		&:active {
			box-shadow: none;
		}
	}

	.loading-overlay {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 15;
		position: absolute;

		@if $enable-rounded {
			-moz-border-radius: $border-radius;
			-webkit-border-radius: $border-radius;
			border-radius: $border-radius;
		}
	}
}
