@import "../variables";

/*---  Smaller than MD ---*/
@media (max-width: map-get($grid-breakpoints, md)) {
	.bu-wizard {
		.register-nav {
			flex-direction: column;
			margin-bottom: 0;

			.nav-unit {
				margin-right: 0;
				margin-bottom: 1em;
			}
		}
	}
	.mb-md-1 {
		margin-bottom: 1em;
	}
	.mt-s-1 {
		margin-top: 1em;
	}

	.navbar-collapse {
		background-color: $white;
		padding: 1em;
		padding-bottom: 2em;
		position: absolute;
		top: $header-height;
		left: 0.5em;
		box-shadow: 0 10px 20px 0 rgba(100, 100, 100, 0.66);
		border-radius: $border-radius;
		width: calc(100% - 1em);
	}
}

/*---  Larger than MD ---*/
@media (min-width: map-get($grid-breakpoints, md)) {
}
